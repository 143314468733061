<template>
    <div class="lawfirm">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="案件信息" fixed placeholder safe-area-inset-top />
        <div class="list">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                >
                <div class="item" v-for='(item,index) in list' :key='index' @click="showPopup(item)">
                    <div class="img">
                        <img :src="imglink" alt="">
                    </div>
                    <div class="info">
                        <div class="name overnowrap">{{item.caseInfoId}}</div>
                        <div class="time">{{item.defName}}</div>
                    </div>
                    <div class="right">
                        <!-- ¥24,000.00 -->
                        <div class="s s-tyle">¥{{item.prosecutionMoney > 0 ?Number(item.prosecutionMoney).formatMoney():"0.00"}}</div>
                        <div class="x s-tyle">¥{{item.feeMoney > 0 ?Number(item.feeMoney).formatMoney():"0.00"}}</div>
                    </div>
                </div>
            </van-list>
           
            <van-popup v-model="show" position="bottom" :style="{ height: 'auto' }" >
                <div>
                    <van-field
                        v-model="dataJson.caseInfoId"
                        readonly 
                        label="案件ID"
                        input-align="right"
                        />
                        <van-field
                        v-model="dataJson.defName"
                        readonly 
                        label="被告"
                        input-align="right"
                        />
                        <van-field
                        v-model="dataJson.prosecutionMoney"
                        readonly 
                        label="诉讼金额"
                        input-align="right"
                        />
                        <van-field
                        v-model="dataJson.feeMoney"
                        readonly 
                        label="系统服务费"
                        input-align="right"
                        />
                        <van-field
                        v-model="dataJson.baseFee"
                        readonly 
                        label="基础费用"
                        input-align="right"
                        />
                        <van-field
                        v-model="dataJson.flowFee"
                        readonly 
                        label="流量费用"
                        input-align="right"
                        />
                        <van-field
                        v-model="dataJson.settlementDate"
                        readonly 
                        label="结算时间"
                        input-align="right"
                        />
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import { button, Toast ,NavBar , Tab, Tabs ,Popup ,Field ,List} from 'vant';
import ShowTotal from '@/components/ShowTotal'
import ItemServiceList from '@/components/ItemServiceList'
export default {
    name:"lawfirm",
    data(){
        return{
            show:false,
            imglink:require("../../assets/lawfirm.png"),
            list:[],
            dataJson:{},
            batchId:this.$route.query.batchId,
            feeType:this.$route.query.feeType,
            loading: false,
            finished: false,
            current:0,
            
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        showPopup(data){
            this.dataJson = JSON.parse(JSON.stringify(data))
            this.dataJson.prosecutionMoney = '￥'+ Number(this.dataJson.prosecutionMoney).formatMoney()
            this.dataJson.feeMoney = '￥'+ Number(this.dataJson.feeMoney).formatMoney()
            this.dataJson.baseFee = '￥'+ Number(this.dataJson.baseFee).formatMoney()
            this.dataJson.flowFee = '￥'+ Number(this.dataJson.flowFee).formatMoney()
            this.show = true
        },
        getService(){
            this.current ++
            if(this.feeType == '30060002')  this.imglink = require("../../assets/lawfirm.png")
            else if(this.feeType == '30060004')  this.imglink = require("../../assets/service.png")
            else if(this.feeType == '30060005')  this.imglink = require("../../assets/mediate.png")
            this.$get(`/finance/api/fee/listPlaintiffCaseFeeInfo`,{feeType:this.feeType,batchId:this.batchId,page:this.current,size:20}).then(res => {
                if (res.code === '200') {
                    if(this.list.length == 0){
                        this.list = res.data.records
                    }else{
                        this.list = [...this.list,...res.data.records]
                    }

                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (res.data.records.length < 20) {
                        this.finished = true;
                    }
                }
            })
        },
        onLoad() {
            setTimeout(() => {
                this.getService(this.status)
            }, 1000);
        },
    },
    mounted() {
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Popup.name]: Popup,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        [List.name]: List,
        ShowTotal,
        ItemServiceList
    }
}
</script>

<style lang="scss" scoped>
    .lawfirm{
        background: #F4F4F4;
        height: 100vh;
    }
</style>